<template>
    <div class="comp-select">
        <select v-model="comp.selected" @change="comp.update">
            <option value="">분류 선택</option>
            <option :value="v" v-for="(v, k) in comp.list" :key="k">{{ v }}</option>
        </select>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'

export default {
    name: 'CarrotCategory',
    props: {
        modelValue: {
            type: [Number, String],
            default: 0,
        },
        ctype: {
            type: String,
            default: ""
        }
    },
    emits: [ 'update:modelValue' ],
    setup(props, { emit }) {
        const toast = useToast()

        const comp = reactive({
            selected : "",
            ctype    : "",
            list : [], total : 0,

            doSearch : () => {
                let params = {
                    type : comp.ctype
                };

                axios.get("/rest/component/getCategoryList", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        comp.list  = res.data.list;
                        comp.total = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            update: () => {
                emit('update:modelValue', comp.selected);
            }
        });

        onMounted(() => {
            // Mounted
            comp.selected = props.modelValue;
            comp.ctype    = props.ctype;

            comp.doSearch();
        });

        return {comp};
   }
}
</script>
<style lang="scss" scoped>
.comp-select {
    display: inline-block;
    select {
        width: 100%;
    }
}
</style>